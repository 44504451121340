import * as Sentry from "@sentry/remix";
import { startTransition, StrictMode } from "react";
import { hydrateRoot } from "react-dom/client";
import { HydratedRouter } from "react-router/dom";

Sentry.init({
  dsn: "https://9d8191838a287de15269546d30b61874@o4506504363966464.ingest.us.sentry.io/4508259301392384",
});

Sentry.setUser((window as any).SENTRY_USER);

startTransition(() => {
  hydrateRoot(
    document,
    <StrictMode>
      <HydratedRouter />
    </StrictMode>
  );
});
